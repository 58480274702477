<template>
  <div>
    <div style="background: #fff;">
      <div class="taskItem flex flex-center">
        <div>任务名称</div>
        <input type="text" placeholder="请填写" v-model="name" />
      </div>
      <div class="taskItem flex flex-center" @click="prizeVisible = true">
        <div>任务类型</div>
        <div class="flex flex-center">
          <div class="taskItem-text">{{ typeName || "请选择类型" }}</div>
          <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div v-if="typeName == '指定人员'">
        <div class="taskItem flex flex-center">
          <div>选择人员</div>
          <div class="flex flex-center">
            <div v-if="selectName.length > 0">{{ selectName.join(",") }}</div>
            <div v-else>请选择人员</div>
          </div>
        </div>
        <div class="remind-check flex wrap">
          <div
            class="remind-check_item"
            @click="selectRemind(item, index)"
            v-for="(item, index) in peopleArray"
            :class="item.select ? 'remind-check_selectItem' : ''"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="remind-textarea">
        <div class="remindText-title">任务说明</div>
        <textarea placeholder="请填写提醒说明" v-model="remark"></textarea>
      </div>

      <div class="taskItem flex flex-center" @click="timePicker = true">
        <div>开始时间</div>
        <div class="flex flex-center">
          <div class="taskItem-text">{{ start_date || "请选择时间" }}</div>
          <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>

      <div class="taskItem flex flex-center" @click="endPicker = true">
        <div>结束时间</div>
        <div class="flex flex-center">
          <div class="taskItem-text">{{ end_date || "请选择时间" }}</div>
          <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div>
      <div class="rulesView">
        <div class="rulesView-title">上传附件</div>
        <img
          class="rulesView-add"
          src="../assets/imgs/add.png"
          alt=""
          @click="triggerUpload"
          v-if="!img_url"
        />
        <input
          v-if="!img_url"
          id="filePhoto"
          type="file"
          ref="file"
          accept="image/*"
          hidden
          @change="onInputChange($event)"
        />

        <div class="rightullidiv" v-if="img_url">
          <img :src="img_url" alt="" class="rightulliimg" />
        </div>
      </div>

      <!-- <div class="taskItem flex flex-center">
        <div>选择群聊</div>
        <div class="flex flex-center">
          <div class="taskItem-text">北京群、广州群</div>
          <img class="taskItem-icon" src="../assets/imgs/arrow.png" alt="" />
        </div>
      </div> -->
    </div>

    <!-- <div class="taskCAll">
      <div class="taskC">
        <div class="flex flex-center">
          <div class="taskC-cirle flex-item_center">
            1
          </div>
          <div>
            <div class="taskC-top flex flex-center">
              <div>双11大促活动任务</div>
              <img class="taskC-topImg" src="../assets/imgs/edit.png" alt="" />
            </div>
            <div class="taskC-bottom flex flex-center">
              8月20日 22:00
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div
      class="taskAll-btn flex-item_center"
      @click="router('/CreateTaskRules')"
    >
      添加任务规则
    </div> -->

    <VuePicker
      :data="selectList"
      :showToolbar="true"
      @cancel="prizeVisible = false"
      @confirm="Prizeconfirm"
      :visible.sync="prizeVisible"
    />

    <VuePicker
      :data="timeList"
      :showToolbar="true"
      @cancel="timePicker = false"
      @confirm="startconfirm"
      :visible.sync="timePicker"
    />

    <VuePicker
      :data="timeList"
      :showToolbar="true"
      @cancel="endPicker = false"
      @confirm="endconfirm"
      :visible.sync="endPicker"
    />

    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-btn flex-item_center" @click="goBack()">取消</div>
      <div class="bottom-btn_save flex-item_center" @click="save()">保存</div>
    </div>
  </div>
</template>

<script>
import VuePicker from "vue-pickers";
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import axios from "axios";
import config from "@/config";

export default {
  components: { VuePicker },
  data() {
    return {
      name: "",
      prizeVisible: false,
      selectList: [
        [
          { label: "全员", value: "1" },
          { label: "指定人员", value: "2" },
        ],
      ],
      type: "",
      remark: "",
      typeName: "",
      peopleArray: [],
      select: [],
      selectName: [],
      timeList: [
        [
          { label: "2022", value: "2022" },
          { label: "2023", value: "2023" },
          { label: "2024", value: "2024" },
          { label: "2025", value: "2025" },
          { label: "2026", value: "2026" },
          { label: "2027", value: "2027" },
        ],
        [
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
        ],
        [
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" },
          { label: "6", value: "6" },
          { label: "7", value: "7" },
          { label: "8", value: "8" },
          { label: "9", value: "9" },
          { label: "10", value: "10" },
          { label: "11", value: "11" },
          { label: "12", value: "12" },
          { label: "13", value: "13" },
          { label: "14", value: "14" },
          { label: "15", value: "15" },
          { label: "16", value: "16" },
          { label: "17", value: "17" },
          { label: "18", value: "18" },
          { label: "19", value: "19" },
          { label: "20", value: "20" },
          { label: "21", value: "21" },
          { label: "22", value: "22" },
          { label: "23", value: "23" },
          { label: "24", value: "24" },
          { label: "25", value: "25" },
          { label: "26", value: "26" },
          { label: "27", value: "27" },
          { label: "28", value: "28" },
          { label: "29", value: "29" },
          { label: "30", value: "30" },
          { label: "31", value: "31" },
        ],
      ],
      timePicker: false,
      start_date: "",
      end_date: "",
      endPicker: false,
      img_url: "",
    };
  },
  async mounted() {
    let res = await request._peopleList({});
    if (_.toInteger(res.code) === 1) {
      var result = res.data;
      result.forEach((item) => {
        item.select = false;
      });
      this.peopleArray = result;
    }
    console.log("指定人员", res);
  },
  methods: {
    async save() {
      if (this.name == "") {
        Toast("请输入姓名");
        return false;
      }
      if (this.remark == "") {
        Toast("请输入任务说明");
        return false;
      }
      if (this.type == "") {
        Toast("请选择方式");
        return false;
      }

      if (this.type == 2 && this.select.length == 0) {
        Toast("请选择人员");
        return false;
      }

      if (this.start_date == "") {
        Toast("请选择任务开始时间");
        return false;
      }

      if (this.end_date == "") {
        Toast("请选择任务结束时间");
        return false;
      }

      if (this.img_url == "") {
        Toast("请选择图片");
        return false;
      }
      let that = this;
      var userids = this.select.length == 0 ? "" : this.select.join(",");
      let res = await request._addTask({
        name: this.name,
        remark: this.remark,
        type: this.type,
        start_date: this.start_date,
        end_date: this.end_date,
        img_url: this.img_url,
        userids,
      });
      if (_.toInteger(res.code) === 1) {
        Toast.success("添加成功");
        setTimeout(() => {
          that.$router.back();
        }, 1200);
      } else {
        Toast(res.msg);
      }
    },
    goBack() {
      this.$router.back();
    },
    selectRemind(item, index) {
      this.peopleArray[index].select = !this.peopleArray[index].select;
      this.select = [];
      this.selectName = [];
      this.peopleArray.forEach((item) => {
        if (item.select) {
          this.select.push(item.userid);
          this.selectName.push(item.name);
        }
      });
    },
    router(path) {
      this.$router.push(path);
    },
    startconfirm(res) {
      console.log("开始时间", res);
      this.start_date = `${res[0].value}-${res[1].value}-${res[2].value}`;
    },
    endconfirm(res) {
      this.end_date = `${res[0].value}-${res[1].value}-${res[2].value}`;
    },
    Prizeconfirm(res) {
      console.log("res", res);
      this.typeName = res[0].label;
      this.type = res[0].value;
      // this.$set(this.postData[this.index], "prize_id", res[0].value);
    },
    //点击图片触发文件上传
    triggerUpload() {
      $("#filePhoto").click();
    },
    //文件上传到浏览器触发事件
    async onInputChange(el) {
      var that = this;
      var file = el.target.files[0];
      console.log(file);
      var type = file.type.split("/")[0];

      let formData = new FormData();
      formData.append("file", file);
      console.log(formData);
      // let res = await request._uploadImg(formData);
      axios({
        method: "post",
        url: config.baseUrl.dev + "workapi/mobile.User/upload",
        data: formData,
      }).then(
        (res) => {
          console.log("Res", res);
          if (res.data.code == 1) {
            that.img_url = res.data.data.img_url;
          }
        },

        (err) => {}
      );
    },
  },
};
</script>

<style scoped>
.remind-textarea {
  margin: 0 auto;
  width: 670px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  padding: 64px 0 26px 0;
}

.remindText-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
}

textarea {
  outline: none;
  border: 0;
  width: 670px;
  margin-top: 38px;
  height: 110px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.rulesView {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
}

.rulesView-title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 0 37px 0;
}

.rulesView-add {
  width: 180px;
  height: 180px;
  background: #ededf7;
  border-radius: 16px 16px 16px 16px;
}

.taskItem {
  width: 670px;
  padding: 64px 0 26px 0;
  border-bottom: 1px solid #ededf7;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin: 0 auto;
  background: #fff;
  justify-content: space-between;
}

.taskItem input {
  text-align: right;
  outline: none;
  border: 0;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}

.taskItem-icon {
  width: 12px;
  height: 20px;
  margin-left: 8px;
}

.taskItem-text {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.taskC {
  width: 670px;
  margin: 0 auto;
}

.taskC-cirle {
  font-size: 32px;
  font-family: Akrobat-Bold, Akrobat;
  font-weight: bold;
  color: #ffffff;
  width: 54px;
  height: 54px;
  background: #0558f4;
  border-radius: 50%;
  margin-right: 46px;
}

.taskCAll {
  padding: 83px 0 48px 0;
  background: #fff;
}

.taskC-top {
  width: 568px;
  height: 76px;
  padding: 0 0 0 16px;

  background: #f4f5f7;
  justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
}

.taskC-bottom {
  width: 568px;
  height: 96px;
  padding: 0 0 0 16px;
  border-radius: 0 0 16px 16px;
  border: 1px solid #e0e4eb;
}

.taskAll-btn {
  width: 670px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 10px 50px 1px rgba(5, 88, 244, 0.1);
  border-radius: 16px 16px 16px 16px;
  margin: 48px auto 0;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-btn {
  width: 325px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
  margin: 15px 0 0 0;
}

.bottom-btn_save {
  width: 325px;
  margin: 15px 0 0 20px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.taskC-topImg {
  width: 27px;
  height: 28px;
  margin-right: 30px;
}

.remind-check {
  width: 670px;
  padding: 52px 0 88px 0;
  margin: 0 auto;
  border-bottom: 1px solid #ededf7;
}

.remind-check_item {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  background: #f1f3ff;
  border-radius: 4px 4px 4px 4px;
  padding: 7px 39px;
  margin: 0 17px 17px 0;
}

.remind-check_selectItem {
  color: #0558f4;
  border: 1px solid #0558f4;
  background: #e8f0fe;
}

.rightullidiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  padding-bottom: 260px;
}

.rightulliimg {
  max-width: 100%;
  max-height: 700px;
}
</style>
